import AOS from "aos"
import "aos/dist/aos.css"
import Link from "next/link"
import { useEffect } from "react"
import { Container } from "./styles"

export const Footer = () => {
  useEffect(() => {
    AOS.init()
  }, [])

  const footerOpts = [
    {
      title: "Company",
      opts: [
        {
          path: "/",
          name: "About Us",
        },
        {
          path: "/privacy",
          name: "Privacy Policy",
        },
        {
          path: "/terms-of-use",
          name: "Terms of Use",
        },
        {
          path: "/support",

          name: "FAQs",
        },
      ],
    },
    {
      title: "Products",
      opts: [
        {
          path: "/remittance",
          name: "Cross-Border",
        },
        {
          path: "/",
          name: "Bills Payment",
        },
        {
          path: "/",
          name: "Virtual Cards",
        },
      ],
    },
    {
      title: "Connect",
      opts: [
        {
          name: "Twitter",
          path: "https://twitter.com/gigxpad?s=21&t=xkS5pPhzTfaADSr1VfO0yw",
        },
        {
          name: "Instagram",
          path: "https://instagram.com/gigxpad?igshid=MWI4MTIyMDE=",
        },
        {
          name: "Telegram",
          path: "http://t.me/gigxcommunity",
        },
      ],
    },
  ]

  return (
    <Container>
      <div className="footer__logo">
        <img src="/logo_white.png" alt="" className="nav__logo" width={100} />
      </div>
      <div className="">
        <div className="footer__links">
          {footerOpts.map((opts, i) => (
            <div className="" key={i}>
              <h5>{opts.title}</h5>
              <div className="">
                {opts.opts.map((opt, i) => (
                  <Link href={opt.path} key={i}>
                    <p key={i}>{opt.name}</p>
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="footer__last">
          <div className="">
            <h5>CONTACT US</h5>
            <div className="">
              <a href="mailto:support@gigxpad.com">
                <p>support@gigxpad.com</p>
              </a>
            </div>
          </div>
          <div className="">
            <h5>SIGN UP TO OUR NEWSLETTER</h5>
            <div className="app__subscribe">
              <input type="text" placeholder="Email Address" />
              <button className="inner__btn">Subscribe</button>
            </div>
            <button className="outer__btn">Subscribe</button>
          </div>
        </div>
      </div>
    </Container>
  )
}
