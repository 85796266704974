import styled from "styled-components"

const Nav = styled.div`
  position: relative;

  @media (max-width: 768px) {
    /* border-bottom: 1px solid #000; */
    box-shadow: 1px 2px 9px #0000001a;
  }

  .navbar {
    align-self: center;
    padding: 20px 164px;
    min-height: 4vh;
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 900px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    @media (max-width: 768px) {
      padding: 0;
    }

    .nav__logo {
      @media (max-width: 768px) {
        padding: 33px 36px;
      }
    }
  }

  .menu {
    display: flex;
    color: #a7a7a7 !important;
    font-weight: 700;
    gap: 67px;
    align-items: center;

    @media (max-width: 768px) {
      display: none;
    }

    &__item {
      text-decoration: none !important;
      font-size: 14px;
      color: black;
      cursor: pointer;

      &__dd {
        position: absolute;
        background-color: white;
        box-shadow: 1px 2px 9px #0000001a;
        padding: 36px 32px;
        margin-top: 16px;
        border-radius: 24px;
        display: flex;
        flex-direction: column;
        gap: 27px;
        cursor: default;

        .inner {
          display: flex;
          gap: 24px;
          align-items: center;

          img {
            width: 35px;
            height: 35px;
          }

          &__text {
            p {
              &:nth-child(1) {
                font-family: "Rubik-Bold";
                font-size: 12px;

                &:hover {
                  color: #e25a5a;
                  cursor: pointer;
                }
              }
              &:nth-child(2) {
                font-size: 10px;
                color: #67777e;
              }
            }
          }
        }
      }

      &__text {
        font-size: 14px;

        &:hover {
          color: #e25a5a;
        }
      }

      &__active {
        font-size: 14px;
        color: #e25a5a;
      }
    }
  }

  .nav_icon {
    background: #162e38;
    cursor: pointer;
    border: none;
    outline: none;
    padding: 32px 23px;

    @media (min-width: 769px) {
      display: none;
    }

    img {
      width: 25px;
    }
  }

  .overlay {
    /* position: absolute; */
    height: 100vh;
    width: 100vw;
    z-index: 29;
    background: #162e38;
    transition: height 0.4s ease-in-out;

    @media (min-width: 769px) {
      display: none;
    }

    &__header {
      justify-content: space-between;
      align-items: center;
      width: 100%;
      display: flex;

      &__logo {
        padding: 33.8px 36px;
      }

      &__close {
        background-color: #fff;
        padding: 32px 27px;
        cursor: pointer;
      }
    }

    &__menu {
      z-index: 30;
      padding: 20px 0;

      .menu__item {
        opacity: 1;
        font-size: 18px;
        margin: 50px 0px;
        padding-left: 32px;
        transition: opacity 0.4s ease-in-out;
      }

      .menu__itemed {
        background-color: #234857;
        padding: 36px 32px;
        color: #f2f6f7;
        display: flex;
        flex-direction: column;
        gap: 27px;

        .inners {
          display: flex;
          gap: 24px;

          img {
            width: 35px;
            height: 35px;
          }

          .menu__item__sub {
            p {
              &:nth-child(1) {
                font-size: 14px;
                font-family: "Rubik-Medium";
                margin-bottom: 4px;
              }
              &:nth-child(2) {
                font-size: 12px;
              }
            }
          }
        }
      }

      .menu__itemList {
        color: #fff;
      }

      .menu__sub {
        /* background-color: #24485750; */
      }

      .menu__item:nth-child(2) {
        margin: 50px 0px;
      }
    }
  }
`
export default Nav
