import styled from "styled-components"

const Container = styled.div`
  width: 100%;
  position: relative;

  .link__container {
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 100;
  }
`
export default Container
