import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react"
import getConfig from "next/config"

interface CustomError {
  data: {
    message: string
    status: any
  }
  status: number
}

const {
  publicRuntimeConfig: { processEnv },
} = getConfig()

const { NEXT_PUBLIC_API_KEY, NEXT_PUBLIC_API_URL } = processEnv

const requestHeaders = {
  // "X-API-KEY": process.env.NEXT_PUBLIC_API_KEY,
  "X-API-KEY": NEXT_PUBLIC_API_KEY,
}

// const baseUrl = process.env.NEXT_PUBLIC_API_URL
const baseUrl = NEXT_PUBLIC_API_URL

export const ratesApi = createApi({
  reducerPath: "ratesApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
  }) as BaseQueryFn<string | FetchArgs, unknown, CustomError, {}>,
  endpoints: (builder) => ({
    getRates: builder.query({
      query: () => {
        return {
          url: "/otc-rates",
          headers: requestHeaders,
        }
      },
    }),
    updateRates: builder.mutation({
      query: (body) => {
        return {
          url: "/otc-rates",
          headers: requestHeaders,
          body,
          method: "PATCH",
        }
      },
    }),
    addRates: builder.mutation({
      query: (body) => {
        return {
          url: "/otc-rates",
          headers: requestHeaders,
          body,
          method: "PATCH",
        }
      },
    }),
  }),
})

export const { useGetRatesQuery, useUpdateRatesMutation, useAddRatesMutation } =
  ratesApi
