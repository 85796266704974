import styled from "styled-components"
import { colors } from "@components/application/Theme"

export const Container = styled.div`
  background-color: #162e38;
  padding: 4.811rem 8.563rem;
  display: flex;
  color: #fff;
  flex-wrap: wrap;
  margin-top: 300px;

  @media (max-width: 478px) {
    margin-top: 8px;
    padding: 135.56px 36px !important;
  }

  @media (max-width: 1204px) {
    gap: 50px;
    padding: 50px;
  }

  h5 {
    font-size: 16px;
    font-family: "Rubik-Bold";
    margin-bottom: 32px;
    text-transform: uppercase;
  }

  p {
    margin-bottom: 26px;
    font-size: 12px;
    font-weight: normal;
    cursor: pointer;

    @media (max-width: 478px) {
      font-size: 16px;
    }
  }

  .footer {
    &__logo {
      width: 20%;
    }

    &__links {
      display: flex;
      flex-wrap: wrap;
      gap: 200px;
      margin-bottom: 60px;

      @media (max-width: 771px) {
        gap: 100px;
      }
    }

    &__last {
      gap: 150px;
      display: flex;
      flex-wrap: wrap;

      a {
        text-decoration: none;
        color: #fff;
      }

      p {
        font-size: 16px;
      }

      @media (max-width: 850px) {
        gap: 20px;
        flex-direction: column;
      }

      @media (max-width: 685px) {
        gap: 20px;
        border-top: 1px solid #ffffff50;
        padding-top: 50px;
      }
    }
  }

  .app__subscribe {
    background-color: #ffffff10;
    width: 474px;
    max-width: calc(100% - 25px);
    /* height: 64px; */
    display: flex;
    justify-content: space-between;
    padding: 13px 12px;
    border-radius: 12px;

    @media (max-width: 786px) {
      width: 100%;
    }

    input {
      border: none;
      outline: none;
      width: 100%;
      color: #ffffff;
      background-color: transparent;

      @media (max-width: 478px) {
        background-color: transparent;
        padding: 12px 14px;
      }
    }

    .inner__btn {
      background-color: ${colors.primary};
      border: none;
      outline: none;
      color: #fff;
      padding: 16.5px 25px;
      border-radius: 5px;

      @media (max-width: 478px) {
        display: none;
      }
    }
  }

  .outer__btn {
    background-color: ${colors.primary};
    border: none;
    outline: none;
    color: white;
    padding: 22.15px 25px;
    border-radius: 12px;
    width: 100%;
    margin-top: 17px;
    font-family: "Rubik-Bold";
    font-size: 14px;

    @media (min-width: 478px) {
      display: none;
    }
  }
`
