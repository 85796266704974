import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/dist/query"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import { combineReducers } from "redux"
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist"
import storage from "redux-persist/lib/storage"
import thunk from "redux-thunk"
import { ratesApi } from "services/ratesApi"
import { remitApi } from "services/remitApi"
import authReducer from "./slices/auth"
import themeReducer from "./slices/theme"

const reducers = combineReducers({
  theme: themeReducer,
  auth: authReducer,
  [remitApi.reducerPath]: remitApi.reducer,
  [ratesApi.reducerPath]: ratesApi.reducer,
})

const persistConfig = {
  key: "root",
  storage,
  blacklist: [ratesApi.reducerPath, "auth"],
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(thunk, remitApi.middleware, ratesApi.middleware),
})

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
setupListeners(store.dispatch)

export default store
