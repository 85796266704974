import AppWrapper from "@components/application/AppWrapper"
import type { AppProps } from "next/app"
import App from "next/app"
import Head from "next/head"
import { useEffect } from "react"
import { useRouter } from "next/router"
import { Provider } from "react-redux"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import store from "../store"
import "../styles/globals.css"
import Layout from "src/layout"

function XApp({ Component, pageProps }: AppProps) {
  const router = useRouter()

  useEffect(() => {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init("974622796859103")
        ReactPixel.pageView()

        router.events.on("routeChangeComplete", () => {
          ReactPixel.pageView()
        })
      })
  }, [router.events])

  return (
    <>
      <Head>
        <title>XPAD - The Borderless Wallet</title>
        <meta
          name="description"
          content="The secure and simple way to send and receive money across borders. Make secure and affordable cross-border transactions on XPAD."
        />
        <meta
          name="facebook-domain-verification"
          content="mdp24e8mq4xxbsy9tzuhdd14ssmw8k"
        />
      </Head>
      <Provider store={store}>
        <AppWrapper>
          <ToastContainer />
          <Layout>{Component && <Component {...pageProps} />}</Layout>
        </AppWrapper>
      </Provider>
    </>
  )
}

XApp.getInitialProps = async (appContext: any) => {
  const appProps = await App.getInitialProps(appContext)
  return { ...appProps }
}

export default XApp
